@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

/* 기본 폰트 설정 */
* {
  font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 커스텀 스타일 */
.toast-enter {
  transform: translateY(100%);
  opacity: 0;
}

.toast-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 300ms ease-out;
}

.toast-exit {
  transform: translateY(0);
  opacity: 1;
}

.toast-exit-active {
  transform: translateY(100%);
  opacity: 0;
  transition: all 300ms ease-in;
}

.modal-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.modal-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.modal-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.modal-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* 마크다운 컨텐츠 스타일링 */
/* .markdown-content {
  @apply prose prose-slate max-w-none;
}

.markdown-content pre {
  @apply bg-gray-100 p-4 rounded-md overflow-x-auto;
}

.markdown-content code {
  @apply bg-gray-100 px-1 rounded;
}

.markdown-content table {
  @apply border-collapse border border-gray-300;
}

.markdown-content th,
.markdown-content td {
  @apply border border-gray-300 px-4 py-2;
}

.markdown-content blockquote {
  @apply border-l-4 border-gray-300 pl-4 italic;
} */
